import './styles/index.scss';

import Vue from 'vue';
import App from './App.vue';
import TheNav from './components/TheNav.vue';
import router from './router';

Vue.component('the-nav', TheNav);

Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App),
}).$mount('#app');
