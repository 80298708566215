<script>

export default {
  data() {
    return {
      reasonsToJoin: [{
        title: '8hrs',
        label: 'saved per hire',
      },
      {
        title: '36%',
        label: 'decrease in no-show rate',
      },
      {
        title: '51%',
        label: 'of Gen Z don\'t use traditional job boards',
      }],
    };
  },
};

</script>

<template>
  <section class="banner" ref="banner">
    <div class="banner_wrapper">
      <div class="banner_stats">
        <ul class="banner_reasons">
          <li v-for="one in reasonsToJoin" :key="one.text" class="banner_reasons_item">
            <h3 class="banner_reasons_item_title">{{one.title}}</h3>
            <p class="banner_reasons_item_label">{{one.label}}</p>
          </li>
        </ul>
      </div>

      <div class="banner_covid">
        <p class="banner_covid_content">
          <span class="banner_covid_content_title">COVID-19:</span>
          reduce face-to-face contact. Hiring managers can prescreen and then interview the most qualified applicants before hiring.
        </p>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1024px) {
  .banner {
    margin-top: 60px;
    position: absolute;
    width: 100%;
    left: 0;

    &_covid {
      background-color: #7adfdc;
      padding: 10px 15px 3px 15px;
      &_content {
        font-size: 15px;
        margin: 0 auto;
        &_title {
          font-weight:bold;
        }
      }
    }

    &_stats {
      background-color: #776cf1;
      color: white;
    }

    &_reasons {
      display: flex;
      justify-content: center;

      &_item {
        width: 80px;
        margin: 30px 15px 20px 15px;
        text-align: center;

        &_title {
          font-weight: 800;
          font-size: 32px;
        }

        &_label {
          margin-top: 10px;
          font-weight: 200;
          font-size: 12px;
          font-weight: 300;

          &:before {
            content: '-';
            display: block;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .banner {
    margin-top: 100px;
    position: absolute;
    width: 100%;
    left: 0;

    &_covid {
      background-color: #7adfdc;
      padding: 10px 80px 3px 80px;
      &_content {
        font-size: 18px;
        max-width: 1000px;
        margin: 0 auto;
        &_title {
          font-weight:bold;
        }
      }
    }

    &_stats {
      background-color: #776cf1;
      color: white;
    }

    &_reasons {
      display: flex;
      justify-content: center;

      &_item {
        width: 170px;
        margin: 50px 50px 40px 50px;
        text-align: center;

        &_title {
          font-weight: 800;
          font-size: 56px;
        }

        &_label {
          margin-top: 10px;
          font-weight: 200;
          font-size: 16px;
          font-weight: 300;

          &:before {
            content: '-';
            display: block;
          }
        }
      }
    }
  }
}
</style>
