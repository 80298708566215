<script>
export default {
  name: 'bannerCandidateApp',

  data() {
    return {
      videosTexts: [{
        videomp4: '/videos/employee/new_1.PNG',
      },
      {
        videomp4: '/videos/employee/new_2.png',
      }, {
        videomp4: '/videos/employee/new_3.PNG',
      }],
    };
  },
};
</script>

<template>
  <section class="banner">
    <h2 class="banner_title">
      Candidate App
    </h2>
    <p class="banner_text">We also provide a platform that helps GenZ job seekers to build their professional footprint, get career advice, and engage with their future employers.</p>

    <ol class="banner_videos">
      <li v-for="one in videosTexts" :key="one.text" class="banner_videos_item">
        <img :src="one.videomp4">
      </li>
    </ol>

  </section>
</template>

<style lang="scss" scoped>
.banner {
  padding: 52px 0;

  &_text {
    padding: 30px 80px 30px 80px;
    font-size: 22px;
  }

  &_title {
    margin-top: 100px;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 24px;
    padding-left: 80px;
    color: black;
  }

  &_videos {
    display: flex;
    justify-content: space-around;
    margin-top: 36px;

    &_item {
      width: 320px;
    }

    img {
      overflow: hidden;
      display: block;
      width: 100%;
      max-width: 256px;
      margin: auto;
      border-radius: 30px;
      box-shadow: 0 2px 50px rgba($accent-color, 0.5);
      outline: none;
    }

    &_title {
      margin-top: 40px;
      font-size: 22px;
      font-weight: 500;
    }
  }

  @include touch {
    padding: 28px 0;

    &_videos {
      flex-wrap: wrap;

      &_item {
        flex: none;
        width: 100%;
        margin-bottom: 64px;
      }
    }
  }
}
</style>
