<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home3.json';

import Api from '@/services/Api';

export default {

  data() {
    return {
      mobileTestimonials: [
        {
          logo1: '/logos/ross.png',
          name1: 'Brittany.',
          title1: 'General Manager',
          text1: 'It\'s super easy to navigate! Candidates set up their own interview. One girl had to reschedule and was able to talk to us in the chat instead of going back and forth over email and voicemails.',
          logo2: '/logos/everlane.png',
          name2: 'Maysie Childs',
          title2: 'Store Manager',
          text2: 'Heroes really opens up the candidatee pool and lets us see what is out there quickly and efficiently. Super addictive and insane customer support.',
        },
        {
          logo1: '/logos/wendys.png',
          name1: 'Jaegar',
          title1: 'General Manager',
          text1: 'I am setting up my own schedule with the times I am available for interviews. It cuts my time in half by helping me refine my own interviewing process. I also find more candidates quicker!',
          logo2: '/logos/jambajuice.png',
          name2: 'Ian Shocklie',
          title2: 'District Manager',
          text2: 'We love to screen for personality & fit with videos. Plus the recruiter app with the chat and tracking is so convenient. Way more intuitive than our last system.',
        },
        {
          logo1: '/logos/walgreens.png',
          name1: 'Dave Masuku',
          title1: 'Store Manager',
          text1: 'Due to mass unemployment, we have over 650 applicants coming at a time. The videos help tremendously with the pre-screening process.',
          logo2: '/logos/casper.png',
          name2: 'Te Iwi Paoa',
          title2: 'Store Manager',
          text2: 'Get a face and personality for each candidate before the in-person interview. Heroes is a life-saver, I hire in 2 days now!',
        },
      ],
      circles: {
        style: '',
        items: [],
        isAnimating: false,
      },
      item: {
        style: '',
        isAnimating: false,
      },
      current: {},
      modalIsOpen: false,
      form: {
        name: '',
        company: '',
        phone: '',
        email: '',
        positions: '',
      },
    };
  },

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);
  },


  methods: {

    openForm() {
      this.modalIsOpen = true;
    },

    sendForm() {
      Api.saveHR(this.form)
        .then(() => {
          this.modalIsOpen = false;
          alert('Request sent!');
        })
        .catch(() => alert('An error occured, please try again!'));
    },

    goToTestimonial(testimonial, i) {
      if (!this.circles.items.length || this.isAnimating) {
        return;
      }

      this.current = testimonial;

      const duration = 600;
      const first = this.circles.items.slice(i).map(one => one.item);
      const last = this.circles.items.slice(0, i).map(one => one.item);
      const total = [...first, ...last];
      const currentAngle = 360 / total.length * i;
      const rotate = currentAngle > 180 ? (360 - currentAngle) : -currentAngle;
      this.circles.style = {
        ...this.circles.style,
        transform: `rotate(${rotate}deg)`,
        transition: `all ${duration / 1000}s`,
      };

      this.item.style = {
        ...this.item.style,
        transform: `rotate(${-rotate}deg)`,
        transition: `all ${duration / 1000}s`,
      };

      this.isAnimating = true;
      setTimeout(() => {
        this.isAnimating = false;
        this.placeAroundCircle(total);
      }, duration);
    },

    placeAroundCircle(items) {
      const itemSize = 80;
      const radius = itemSize * items.length / 3;
      const width = radius * 2;
      const height = radius * 2;

      this.circles.style = {
        width: `${width}px`,
        height: `${height}px`,
      };

      this.item.style = {
      };

      this.circles.items = items.map((item, i) => {
        const angle = (i / (items.length / 2)) * Math.PI;
        const x = Math.round(width / 2 + (radius - itemSize / 2) * Math.cos(angle));
        const y = Math.round(height / 2 + (radius - itemSize / 2) * Math.sin(angle));

        return {
          style: {
            left: `${x}px`,
            top: `${y}px`,
            width: `${itemSize}px`,
            height: `${itemSize}px`,
            marginLeft: `-${itemSize / 2}px`,
            marginTop: `-${itemSize / 2}px`,
          },
          item,
        };
      });
    },

    handleScroll() {
      const floater = document.getElementById('floater');
      const limit = 150;
      const offset = document.body.offsetHeight - (window.scrollY + window.innerHeight);
      if (offset < limit) {
        floater.style.display = 'none';
      }
      if (offset >= limit) {
        floater.style.display = 'block';
      }
    },
  },

  created() {
    if (window.screen.width > 1214) {
      Api.getTestimonials()
        .then((testimonials) => {
          this.placeAroundCircle(testimonials.records);
          this.current = this.circles.items[0];
        });
    }
    window.addEventListener('scroll', this.handleScroll);
  },

  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<template>
  <section class="banner" ref="banner">

    <h3 class="banner_overtitle">Employer Branding</h3>
    <p class="banner_text">In this tight job market, employers MUST advertise outside of traditional job boards to staff their teams.</p>

    <h3 class="banner_title">Companies love Heroes!</h3>

    <div class="banner_content desktop">
      <div class="banner_carousel">
        <div class="banner_carousel_wrap" :style="circles.style">
          <button
            v-for="(one, i) in circles.items"
            :key="i"
            class="banner_carousel_item"
            :class="{'is-active': i === 0}"
            :style="one.style"
            type="button"
            @click="goToTestimonial(one, i)"
          >
            <div class="banner_carousel_img" :style="item.style">
              <img :src="one.item.fields.company_logo[0].url" :alt="one.item.fields.company_name">
            </div>
          </button>
        </div>
      </div>
      <article v-if="current.item" class="banner_testimonial">
        <h4 class="banner_testimonial_quoteName">{{ current.item.fields.recuiter_name }}</h4>
        <p class="banner_testimonial_quoteTitle">
          {{ current.item.fields.recruiter_job }}
          at
          {{ current.item.fields.company_name }}
        </p>
        <p class="banner_testimonial_quoteText">{{  current.item.fields.quote }}</p>
      </article>
    </div>

    <div class="banner_content mobile">
      <div class="mobile">
        <div v-for="one in mobileTestimonials" :key="one.name1" class="banner_content_testimonials">
          <div class="banner_content_testimonials_item">
            <img class="banner_content_testimonials_item_logo" :src="one.logo1" height=70 width=70 />
            <h3 class="banner_content_testimonials_item_name">{{one.name1}}</h3>
            <p class="banner_content_testimonials_item_title">{{one.title1}}</p>
            <p class="banner_content_testimonials_item_text">{{one.text1}}</p>
          </div>
          <div class="banner_content_testimonials_item">
            <img class="banner_content_testimonials_item_logo" :src="one.logo2" height=70 width=70 />
            <h3 class="banner_content_testimonials_item_name">{{one.name2}}</h3>
            <p class="banner_content_testimonials_item_title">{{one.title2}}</p>
            <p class="banner_content_testimonials_item_text">{{one.text2}}</p>
          </div>
        </div>
      </div>
    </div>

    <div class="banner_button_container">
      <button class="banner_button" @click="openForm()">
        Chat with Current Clients!
      </button>
    </div>

    <transition name="fade">
      <div class="banner_modal modal" v-show="modalIsOpen">
        <div class="modal_overlay" @click="modalIsOpen = false"></div>

        <div class="modal_flex">

          <div class="modal_covid">
            <h3 class="modal_covid_title">Hire Smarter with Heroes Jobs</h3>
            <p>Our virtual hiring event platform empowers companies to hire more quickly, efficiently, and economically. Gain a competitive advantage and streamline the hiring process for HR, Hiring Managers, and Applicants.</p>
            <img class="modal_covid_image" src="@/assets/img/covid19.png" />
          </div>

          <div class="modal_content">
            <button class="modal_close" type="button" @click="modalIsOpen = false">
              <svg width="24" height="24" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
                <path fill="none" d="M8.55,7.968l7.301-7.301c0.153-0.152,0.153-0.399,0-0.551c-0.152-0.152-0.397-0.152-0.55,0L8,7.417
                  L0.699,0.116c-0.152-0.152-0.399-0.152-0.551,0s-0.152,0.399,0,0.551l7.301,7.301L0.147,15.27c-0.152,0.151-0.152,0.398,0,0.55
                  c0.152,0.153,0.399,0.153,0.551,0L8,8.519l7.301,7.301c0.152,0.153,0.397,0.153,0.55,0c0.153-0.151,0.153-0.398,0-0.55L8.55,7.968z
                  "></path>
              </svg>
            </button>
            <div class="modal_form">
              <form @submit.prevent="sendForm">
                <h3 class="signUp-title">30 Day Free Trial!</h3>
                <p class="signUp-intro">Start your free trial today and fill positions 5 times faster. Register now and a member of our team will contact you right away.</p>
                <label>Name</label>
                <input type="text" v-model.trim="form.name" required="required">
                <label>Email</label>
                <input type="text" v-model.trim="form.email" required="required">
                <label>Company Name</label>
                <input type="text" v-model.trim="form.company" required="required">
                <div class="modal_halfliners">
                  <div class="modal_halfliners_itemLeft">
                    <label>Phone Number</label>
                    <input type="tel" v-model.trim="form.phone" required="required">
                  </div>
                </div>
                <button type="submit">
                  <span>Start Free Trial</span>
                </button>
              </form>
            </div>

          </div>

        </div>
      </div>
    </transition>

    <button class="floatingButton" id="floater" @click="openForm()">
      Start Free Trial
    </button>

    <div class="bg" ref="bg" style="margin-top:150px;"></div>

  </section>
</template>

<style lang="scss" scoped>

.floatingButton{
  position: fixed;
  width: 260px;
  left: 50%;
  margin-left: -126px;
  bottom: 20px;
  background-color: black;
  padding: 10px 40px 10px 40px;
  font-weight: 300;
  font-size: 24px;
  border-radius: 50px;
  font-family: inherit;
  outline: none;
  border: none;
  color: white;
  text-transform: capitalize;
  box-shadow: 2px 2px 5px #888888;
  cursor: pointer;
  transition-duration: .5s;
  z-index: 3;

  &:hover {
    transform: scale(1.1);
  }
}

@media screen and (max-width: 1215px) {

  .desktop {
    display: none;
  }

  .banner {
    margin-top: 40px;

    &_overtitle {
      margin-top: 100px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 24px;
      padding-left: 80px;
      color: black;
    }

    &_title {
      font-size: 34px;
      font-weight: 700;
      text-transform: capitalize;
    }

    &_text {
      padding: 30px 80px 30px 80px;
      font-size: 22px;
    }

    &_button {
      margin-top: 30px;
      background-color: #776cf1;
      padding: 10px 20px 10px 20px;
      font-weight: 300;
      font-size: 20px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;

      &_container {
        text-align: center;
      }
    }

    &_content {

      &_testimonials {
          display: flex;

        &_item {
          width: 50%;
          padding: 10px 5% 10px 5%;
          float: left;

          &_name {
            font-weight: 800;
          }

          &_title {
            font-style: italic;
          }

          &_text {
            margin-top: 5px;
            font-size: 14px;
          }
        }
      }
    }

  }
}

@media screen and (min-width: 1216px) {

  .mobile {
    display: none;
  }

  .banner {

    margin-top: 350px;

    &_overtitle {
      margin-top: 30px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 24px;
      padding-left: 80px;
      color: black;
    }

    &_title {
      text-transform: uppercase;
      font-weight: 800;
      font-size: 24px;
      padding-left: 80px;
      color: black;
    }

    &_text {
      padding: 30px 80px 100px 80px;
      font-size: 22px;
    }

    &_button {
      margin-top: 20px;
      background-color: black;
      padding: 20px 40px 20px 40px;
      font-weight: 300;
      font-size: 24px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;
      cursor: pointer;
      transition-duration: .5s;

      &:hover {
        transform: scale(1.1);
      }

      &_container {
        text-align: center;
      }
    }

    &_testimonial {
      margin: 0 100px 0 100px;

      &_quoteName {
        font-weight: bold;
        font-size: 18px;
      }

      &_quoteTitle {
        font-weight: 200;
        font-style: italic;
        font-size: 16px;
      }

      &_quoteText {
        font-size: 34px;
        margin-top: 10px;
        &:before {content: '\2018\2018';}
        &:after {content: '\2019\2019';}
      }

    }

    &_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 40px;

      @include touch {
        flex-wrap: wrap;
      }
    }

    &_carousel {
      flex: none;
      position: relative;
      margin-left: -250px;

      &_item {
        position: absolute;
        padding: 0;
        border: 0;
        border-radius: 50%;
        /*background: $white-color;*/
        background: rgba($accent-color2, 0.5);
        outline: none;

        img {
          overflow: hidden;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: transparent;
          object-fit: cover;
        }
      }

      &_item.is-active {
        box-shadow: 0 2px 14px $accent-color2;
        position: absolute;
        padding: 0;
        border: 0;
        border-radius: 50%;
        /*background: $white-color;*/
        background: rgba($accent-color2, 0.5);
        outline: none;

        img {
          overflow: hidden;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          background: transparent;
          object-fit: cover;
        }
      }

      @include touch {
        transform: translate(-50%, 0);
        // transform-origin: 0 0;
      }
    }

  }

}
</style>
