<script>
import lottie from 'lottie-web';
import animationData from '@/assets/animations/home1.json';
import animationDataMobile from '@/assets/animations/homemobile.json';

import BannerEmployersHire from '@/components/BannerEmployersHire.vue';
import BannerRecruitersLove from '@/components/BannerRecruitersLove.vue';
import BannerEmployersStats from '@/components/BannerEmployersStats.vue';
import BannerUnlock from '@/components/BannerUnlock.vue';
import BannerArticles from '@/components/BannerArticles.vue';
import BannerDemo from '@/components/BannerDemo.vue';
import BannerDiverseTeams from '@/components/BannerDiverseTeams.vue';
import BannerCandidateApp from '@/components/BannerCandidateApp.vue';

export default {
  name: 'employers',

  components: {
    BannerEmployersHire,
    BannerRecruitersLove,
    BannerEmployersStats,
    BannerUnlock,
    BannerDemo,
    BannerArticles,
    BannerDiverseTeams,
    BannerCandidateApp,
  },

  data() {
    return {
      faqList: false,
    };
  },

  mounted() {
    const params = {
      container: this.$refs.bg,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      animationData,
    };

    lottie.loadAnimation(params);

    params.container = this.$refs.bgmobile;
    params.animationData = animationDataMobile;

    lottie.loadAnimation(params);
  },

  methods: {
    onOver() {
      this.faqList = true;
    },
    onLeave() {
      this.faqList = false;
    },
  },

};
</script>

<template>
  <div class="employers wrap">
    <the-nav>
      <div class="nav-side-links">
        <div id="faq-dropdown" class="div-link" @mouseover="onOver" @mouseleave="onLeave">
          <a class="the-nav_link" href="#">FAQ</a>
          <transition name="fade">
            <ul v-if="faqList" class="dropdown-menu">
              <li class="dropdown-menu_item"><a href="/faqemployers">I'm an employer.</a></li>
              <li class="dropdown-menu_item"><a href="/faqjobseekers">I'm a jobseeker.</a></li>
            </ul>
          </transition>
        </div>
        <a href="https://blog.heroes.jobs/" class="the-nav_link" target="_blank">Blog</a>
        <router-link active-class="is-active" :to="{ name: 'jobseekers' }">
          Looking for a job?
        </router-link>
      </div>
    </the-nav>
    <banner-employers-hire />
    <banner-recruiters-love />
    <banner-candidate-app />
    <banner-employers-stats />
    <banner-diverse-teams />
    <banner-unlock />
    <banner-articles />
    <banner-demo />

    <div class="bg" ref="bg"></div>
    <div class="bgmobile" ref="bgmobile"></div>
  </div>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1215px) {
  .dropdown-menu_item a {
    font-size: 13px;
    background-color: white;
  }
}

@media screen and (min-width: 1216px) {
  .dropdown-menu_item {
    padding-top: 5px;
    a {
      font-size: 16px;
    }
  }
}

.div-link {
  display: inline-block;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .2s;
}
.fade-enter, .fade-leave-active {
  opacity: 0;
}

.dropdown-menu {
  position: absolute;
  &_item {
    display: block;
    a {
      text-decoration: none;
    }
  }
}

</style>
