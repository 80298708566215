import axios from 'axios';

export default {
  getPeople() {
    return axios.get('https://api.airtable.com/v0/appRrZBJklzvII7hW/people?view=Grid%20view&api_key=keyXR7usfklwDK8aI')
      .then(res => res.data);
  },

  getBrands() {
    return axios.get('https://api.airtable.com/v0/appRrZBJklzvII7hW/brands?&view=Grid%20view&api_key=keyXR7usfklwDK8aI')
      .then(res => res.data);
  },

  saveHR(data) {
    return axios.post('https://v2p2732rf8.execute-api.us-west-1.amazonaws.com/prod/sendform', data)
      .then(res => res.data);
  },

  getTestimonials() {
    return axios.get('https://api.airtable.com/v0/appRrZBJklzvII7hW/testimonials?view=Grid%20view&api_key=keyXR7usfklwDK8aI')
      .then(res => res.data);
  },

};
