import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'jobseekers',
    component: () => import('@/views/JobSeekers.vue'),
  },
  {
    path: '/licenseagreement',
    name: 'licenseagreement',
    component: () => import('@/views/LicenseAgreement.vue'),
  },
  {
    path: '/serviceagreement',
    name: 'serviceagreement',
    component: () => import('@/views/ServiceAgreement.vue'),
  },
  {
    path: '/legals',
    name: 'privacypolicy',
    component: () => import('@/views/PrivacyPolicy.vue'),
  },
  {
    path: '/faqjobseekers',
    name: 'faqjobseekers',
    component: () => import('@/views/FAQJobseekers.vue'),
  },
  {
    path: '/faqemployers',
    name: 'faqemployers',
    component: () => import('@/views/FAQEmployers.vue'),
  },
  {
    path: '/jobseekers',
    name: 'home',
    component: Home,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
