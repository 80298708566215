<script>

export default {
  data() {
    return {
      reasonsToJoin: [{
        logo: '/logos/forbes_logo.png',
        link: '',
      },
      {
        logo: '/logos/wwd_logo.png',
        link: 'https://wwd.com/business-news/human-resources/heroes-jobs-essential-retailers-hiring-efficiency-1203563448/',
      },
      {
        logo: '/logos/yahoo_finance_logo.png',
        link: 'https://finance.yahoo.com/news/essential-retailers-hiring-efficiency-must-160913870.html',
      },
      {
        logo: '/logos/adreessenhorowitz_logo.png',
        link: 'https://a16z.com/2020/05/01/covid-and-the-great-rehiring/',
      }],
    };
  },
};

</script>

<template>
  <section class="banner" ref="banner">
    <div class="banner_articles_container">
      <ul class="banner_articles">
        <li class="banner_articles_item">
          <img class="banner_articles_item_logo" src="/logos/forbes_logo.png" height=150 width=150 />
        </li>
        <li class="banner_articles_item">
          <a href="https://wwd.com/business-news/human-resources/heroes-jobs-essential-retailers-hiring-efficiency-1203563448/" target="_blank">
            <img class="banner_articles_item_logo" src="/logos/wwd_logo.png" height=150 width=150 />
          </a>
        </li>
        <li class="banner_articles_item">
          <a href="https://finance.yahoo.com/news/essential-retailers-hiring-efficiency-must-160913870.html" target="_blank">
            <img class="banner_articles_item_logo" src="/logos/yahoo_finance_logo.png" height=150 width=150 />
          </a>
        </li>
        <li class="banner_articles_item">
          <a href="https://a16z.com/2020/05/01/covid-and-the-great-rehiring/" target="_blank">
            <img class="banner_articles_item_logo" src="/logos/adreessenhorowitz_logo.png" height=150 width=150 />
          </a>
        </li>
      </ul>
    </div>
  </section>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1215px) {
  .banner {
    margin-top: 80px;

    &_articles {
      display: flex;
      justify-content: center;

      &_item {
        margin: 0 10px 0 10px;
        text-align: center;

        &_logo {
          height: 70px;
          width: 70px;
        }
      }

      &_container {
        background-color: #776cf1;
        padding: 0;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }
  }
}

@media screen and (min-width: 1216px) {
  .banner {
    margin-top: 80px;

    &_articles {
      display: flex;
      justify-content: center;

      &_item {
        margin: 0 25px 0 25px;
        text-align: center;
      }

      &_container {
        background-color: #776cf1;
        padding: 0;
        position: absolute;
        width: 100%;
        left: 0;
      }
    }
  }
}
</style>
