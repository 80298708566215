<script>
export default {

  methods: {
    isMobile() {
      return window.screen.width < 1216;
    },
  },

  data() {
    return {
      modalIsOpen: false,
    };
  },
};

</script>

<template>
  <section class="banner" ref="banner">

    <div class="banner_textContainer">
      <h2 class="banner_title">Recruiting GenZ is tough.<br>Let us help.</h2>
      <p class="banner_synopsis">Our mission is to connect GenZ with career opportunities in a way that is relevant to their generation.</p>
      <p class="banner_synopsis">We leverage our community of GenZ jobseekers and develop video-based campaigns on popular GenZ platforms to create an audience for your job listings.</p>
    </div>

    <div class="banner_image">
      <div class="banner_image_video" v-if="!isMobile()">
        <video preload="auto" autoplay="autoplay" muted="muted" loop="loop" id="cover-video" class="desktop">
          <source src="/videos/HalfCoverVideo.mp4" type="video/mp4">
        </video>
      </div>
      <div class="banner_image_img desktop" v-if="!isMobile()">
        <img src="@/assets/img/HalfCoverPicture.png" />
      </div>
      <div class="banner_image_img mobile">
        <img src="/videos/Cover.png" />
      </div>
    </div>

    <div style="clear:both;"></div>

  </section>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1215px) {

  .desktop {
    display: none !important;
  }

  .banner {

    &_textContainer {
      margin-top: 20px;
    }

    &_title {
      font-size: 36px;
      font-weight: 700;
    }

    &_list {
      margin: 10px 0 10px 0;
      font-size: 18px;
      line-height: 1;
    }

    &_synopsis {
      margin-top: 40px;
      font-size: 16px;
    }

    &_button {
      margin-top: 20px;
      background-color: #776cf1;
      padding: 10px 40px 10px 40px;
      font-weight: 300;
      font-size: 20px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;

      &_play {
        height: 25px;
        width: 25px;
        margin: 0 8px -5px 0;
      }
    }

    &_image {
      margin: 0 auto;
      margin-top: 30px;
      max-width: 500px;
      display: flex;

      &_img {
        width: 100%;
      }
    }
  }
}

@media screen and (min-width: 1216px) {

  .mobile {
    display: none;
  }

  .banner {

    &_textContainer {
      padding: 100px 0 0 50px;
      width: 47%;
      display: inline-block;
      float: left;
    }

    &_title {
      font-weight: 700;
      font-size: 44px;
      margin-bottom: 40px;
    }

    &_list {
      margin: 10px 0 10px 0;
      font-size: 24px;
      line-height: 1;
    }

    &_synopsis {
      margin-top: 40px;
      font-size: 18px;
      font-weight: 200;
      line-height: 1.5;
    }

    &_button {
      margin-top: 20px;
      background-color: #776cf1;
      padding: 20px 40px 20px 40px;
      font-weight: 300;
      font-size: 24px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;
      cursor: pointer;
      transition-duration: .5s;

      &_play {
        height: 35px;
        width: 35px;
        margin: 0 10px -8px 0;
      }

      &:hover {
        transform: scale(1.1);
      }
    }

    &_image {
      display: flex;
      float: right;

      &_video {
        width: 300px;
        height: 530px;
      }

      &_img {
        margin-left: -1px;
        width: 300px;
        height: 530px;
      }
    }

    .modal {

    &_video {
      padding: 5px;
    }
    }
  }

  .preloadVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 99999;

    &_wrapper {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    background-color: black;
    z-index: 99998;
    }
  }
}
</style>
