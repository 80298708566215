<script>
import Api from '@/services/Api';

export default {

  data() {
    return {
      modalIsOpen: false,
      form: {
        name: '',
        company: '',
        phone: '',
        email: '',
        positions: '',
      },
    };
  },

  methods: {

    openForm() {
      this.modalIsOpen = true;
    },

    sendForm() {
      Api.saveHR(this.form)
        .then(() => {
          this.modalIsOpen = false;
        })
        .catch(() => alert('An error occured, please try again!'));
    },
  },
};

</script>

<template>
  <section class="banner" ref="banner">

    <div class="banner_row">
      <div class="banner_row_item">
        <img class="banner_row_item_logo" src="@/assets/img/integration.png" height=100 width=100 />
        <p class="banner_row_item_label">Integration</p>
      </div>
      <div class="banner_row_item">
        <img class="banner_row_item_logo" src="@/assets/img/security.png" height=100 width=100 />
        <p class="banner_row_item_label">Secured Data</p>
      </div>
    </div>

    <div class="banner_row">
      <div class="banner_row_item">
        <img class="banner_row_item_logo" src="@/assets/img/multi_manager.png" height=100 width=100 />
        <p class="banner_row_item_label">Multi-manager Access</p>
      </div>
      <div class="banner_row_item">
        <img class="banner_row_item_logo" src="@/assets/img/text_alert.png" height=100 width=100 />
        <p class="banner_row_item_label">Automatic Text Reminders</p>
      </div>
    </div>

    <div class="banner_button_container">
      <button class="banner_button" @click="openForm()">
        Discover more features
      </button>
    </div>

    <transition name="fade">
      <div class="banner_modal modal" v-show="modalIsOpen">
        <div class="modal_overlay" @click="modalIsOpen = false"></div>

        <div class="modal_flex">

          <div class="modal_covid">
            <h3 class="modal_covid_title">Hire Smarter with Heroes Jobs</h3>
            <p>Our virtual hiring event platform empowers companies to hire more quickly, efficiently, and economically. Gain a competitive advantage and streamline the hiring process for HR, Hiring Managers, and Applicants.</p>
            <img class="modal_covid_image" src="@/assets/img/covid19.png" />
          </div>

          <div class="modal_content">
            <button class="modal_close" type="button" @click="modalIsOpen = false">
              <svg width="24" height="24" viewBox="0 0 16 16" enable-background="new 0 0 16 16" xml:space="preserve">
                <path fill="none" d="M8.55,7.968l7.301-7.301c0.153-0.152,0.153-0.399,0-0.551c-0.152-0.152-0.397-0.152-0.55,0L8,7.417
                  L0.699,0.116c-0.152-0.152-0.399-0.152-0.551,0s-0.152,0.399,0,0.551l7.301,7.301L0.147,15.27c-0.152,0.151-0.152,0.398,0,0.55
                  c0.152,0.153,0.399,0.153,0.551,0L8,8.519l7.301,7.301c0.152,0.153,0.397,0.153,0.55,0c0.153-0.151,0.153-0.398,0-0.55L8.55,7.968z
                  "></path>
              </svg>
            </button>
            <div class="modal_form">
              <form @submit.prevent="sendForm">
                <h3 class="signUp-title">30 Day Free Trial!</h3>
                <p class="signUp-intro">Start your free trial today and fill positions 5 times faster. Register now and a member of our team will contact you right away.</p>
                <label>Name</label>
                <input type="text" v-model.trim="form.name" required="required">
                <label>Email</label>
                <input type="text" v-model.trim="form.email" required="required">
                <label>Company Name</label>
                <input type="text" v-model.trim="form.company" required="required">
                <div class="modal_halfliners">
                  <div class="modal_halfliners_itemLeft">
                    <label>Phone Number</label>
                    <input type="tel" v-model.trim="form.phone" required="required">
                  </div>
                </div>
                <button type="submit">
                  <span>Start Free Trial</span>
                </button>
              </form>
            </div>

          </div>

        </div>
      </div>
    </transition>

  </section>
</template>

<style lang="scss" scoped>

@media screen and (max-width: 1215px) {
  .banner {
    margin-top: 630px;

    &_button {
      margin-top: 36px;
      background-color: black;
      padding: 10px 20px 10px 20px;
      font-weight: 300;
      font-size: 24px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;

      &_container {
        text-align: center;
      }
    }

    &_row {
      display: flex;
      justify-content: center;

      &_item {
        border-radius: 40px;
        width: 150px;
        height: 150px;
        box-shadow: 0 0 20px #d6f5f4;
        margin: 10px 10px 30px 10px;
        background-color: white;
        text-align: center;
        padding: 5px 10px 5px 10px;

        &_label {
          margin-top: 15px;
          text-align: center;
          font-size: 14px;
        }

        &_logo {
          margin-top: 8px;
          height: 70px;
          width: 70px;
        }
      }
    }
  }
}

@media screen and (min-width: 1216px) {
  .banner {
    margin-top: 600px;
    margin-bottom: 100px;

    &_button {
      margin-top: 80px;
      background-color: black;
      padding: 20px 40px 20px 40px;
      font-weight: 300;
      font-size: 24px;
      border-radius: 50px;
      font-family: inherit;
      outline: none;
      border: none;
      color: white;
      text-transform: capitalize;
      box-shadow: 2px 2px 5px #888888;
      cursor: pointer;
      transition-duration: .5s;

      &:hover {
        transform: scale(1.1);
      }

      &_container {
        text-align: center;
      }
    }

    &_row {
      display: flex;
      justify-content: center;

      &_item {
        border-radius: 40px;
        width: 300px;
        height: 200px;
        box-shadow: 0 0 20px #d6f5f4;
        margin: 20px 40px 20px 40px;
        background-color: white;
        text-align: center;
        padding: 15px 30px 15px 30px;

        &_label {
          margin-top: 15px;
          text-align: center;
          font-size: 20px;
        }
      }
    }
  }
}
</style>
